import { useRouter } from 'next/router';
import { formatNumber, RoundingStategy } from './formatNumber';
import { usePricingCodes } from './pricingCodes/usePricingCodes';

export const toDollars = (cents: number = 0) => cents / 100;
export const toCents = (dollars: number = 0) => dollars * 100;

type Options = {
  precision?: number;
  currency: string;
  locale: string;
};

export const getFormattedCurrency = (value: number, options?: Options) => {
  return new Intl.NumberFormat(options?.locale || 'en-US', {
    style: 'currency',
    currency: options?.currency || 'AUD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: options?.precision ?? undefined,
    // @ts-ignore
    trailingZeroDisplay: 'stripIfInteger',
  }).format(toDollars(value));
};

export const useCurrency = () => {
  const pricingCodes = usePricingCodes();
  const currency = pricingCodes.options.find((op) => op.code === pricingCodes.current)?.currency;
  const { locale } = useRouter();

  const options = {
    currency,
    locale: locale || 'en-UK',
  };

  const d = (value: number, _options?: Partial<Options>) =>
    getFormattedCurrency(value, { ...options, ...(_options || {}) });

  return { d };
};

/**
 * Legacy number formats
 */

/**
 * Formats a value into a currency string
 * @deprecated useCurrency instead
 *
 * @param value The value in the lowest denomination (cents)
 * @param precision The number of decimal points
 */
export const formatCurrency = (
  value: number,
  precision: number = 0,
  roundingStrategy: RoundingStategy = 'round',
): string => {
  const absValue = Math.abs(value);

  if (absValue == 0) {
    return '$0';
  }

  if (absValue < 100) {
    return `${value}c`;
  }

  return `${value < 0 ? '- ' : ''}$${formatNumber(toDollars(absValue), precision, roundingStrategy)}`;
};

/**
 * @deprecated useCurrency instead
 */
export const d = formatCurrency;
