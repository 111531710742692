import { useShowroomSettings } from '../../services/showrooms/useShowroomSettings';
import { FooterProps } from './types';
import { CupraFooter } from './variants/Cupra';
import GiltrapFooter from './variants/Giltrap';
import PlaceholderFooter from './variants/PlaceholderFooter';
import StandardFooter from './variants/Standard';

export default function Footer(props: FooterProps) {
  const showroomSettings = useShowroomSettings(['footer_variant']);

  switch (showroomSettings?.footer_variant) {
    case 'giltrap':
      return <GiltrapFooter {...props} />;
    case 'placeholder':
      return <PlaceholderFooter {...props} />;
    case 'cupra':
      return <CupraFooter {...props} />;
    default:
      return <StandardFooter {...props} />;
  }
}
