import { round, ceil } from "lodash";

export type RoundingStategy = "round" | "ceil";

export const formatNumber = (
  value: number,
  precision: number = 2,
  roundingStrategy: RoundingStategy = "round"
): string => {
  if (value >= 1e6) {
    return `${(value / 1e6).toFixed(precision)} M`;
  }

  return new Intl.NumberFormat().format(
    roundingStrategy === "round"
      ? round(value, precision)
      : ceil(value, precision)
  );
};

export const n = formatNumber;

export const cardinal = (num: number, digits: number = 0) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatSmallNumber = (value: number): string => {
  switch (value) {
    case 1:
      return "One";
    case 2:
      return "Two";
    case 3:
      return "Three";
    case 4:
      return "Four";
    case 5:
      return "Five";
    case 6:
      return "Six";
    default:
      return formatNumber(value);
  }
};
