import { ReactNode } from 'react';
import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { BsFacebook, BsLinkedin, BsInstagram } from 'react-icons/bs';
import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { MenuItem as TMenuItem } from '../../../../types/menu';
import Button from '../../../Button';
import Container from '../../../Container';
import { FooterCta, FooterProps } from '../../types';
import giltrapGroupLogo from './giltrapGroupLogo.png';
import styles from './index.module.scss';

export default function GiltrapFooter(props: FooterProps) {
  const { legal, className } = props;

  const showroom = useShowroom();

  const showroomSettings = useShowroomSettings([
    'footer_cta',
    'footer_menu',
    'facebook_url',
    'instagram_url',
    'linkedin_url',
  ]);

  const footerCta: FooterCta = showroomSettings.footer_cta;

  const socialMediaIcons = {
    facebook: <BsFacebook />,
    linkedin: <BsLinkedin />,
    instagram: <BsInstagram />,
  };

  const socialLinks: { href: string; icon: ReactNode; label: string }[] = Object.keys(socialMediaIcons)
    .map((media) => ({
      href: showroomSettings[`${media}_url`],
      icon: socialMediaIcons[media],
      label: media,
    }))
    .filter((item) => !!item.href);

  return (
    <footer className={classnames(styles.footer, className)}>
      <div className={styles.top}>
        <Container className={styles.container}>
          {footerCta && (
            <div className={styles.cta}>
              <h2 className={styles.title}>{footerCta.title}</h2>
              <p className={styles.description}>{footerCta.description}</p>
              <Button color="black" href={footerCta.href}>
                {footerCta.label}
              </Button>
            </div>
          )}
          <nav className={styles.menu}>
            <ul>
              {(showroomSettings.footer_menu || []).map((menuItem, i) => (
                <MenuItem key={i} item={menuItem} />
              ))}
            </ul>
            <div className={styles.social}>
              {socialLinks.map((socialLink, i) => (
                <a key={i} href={socialLink.href} aria-label={socialLink.label}>
                  {socialLink.icon}
                </a>
              ))}
            </div>
          </nav>
        </Container>
      </div>

      <div className={styles.bottom}>
        <Container className={styles.container}>
          <a href="https://www.giltrap.com/">
            <Image src={giltrapGroupLogo} width={200} height={36} alt="The Giltrap Group" />
          </a>

          <div className={styles.legal}>
            <p>Giltrap Group has been appointed as the official representative of {showroom.name} in New Zealand.</p>
            <p>
              Giltrap Group © {new Date().getFullYear()}. All rights reserved. Website powered by{' '}
              <a href="https://vyrolabs.com">Vyro Labs</a>.
            </p>
          </div>
        </Container>
      </div>

      {legal && (
        <Container>
          <div className="legal">{legal}</div>
        </Container>
      )}
    </footer>
  );
}

type MenuItemProps = {
  item: TMenuItem;
};

function MenuItem(props: MenuItemProps) {
  const { item } = props;

  const hasChildren = !!item.items?.length;

  return (
    <li className={styles.item}>
      {item.href ? (
        <Link href={item.href}>
          <a>{item.label}</a>
        </Link>
      ) : (
        <span>{item.label}</span>
      )}

      {hasChildren && (
        <ul>
          {item.items.map((child, i) => (
            <MenuItem key={i} item={child} />
          ))}
        </ul>
      )}
    </li>
  );
}
