import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { FooterProps } from '../../types';
import styles from './index.module.scss';
import classnames from 'classnames';
import Container from '../../../Container';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { MenuItem as TMenuItem } from '../../../../types/menu';
import Link from 'next/link';

type MenuItemProps = {
  item: TMenuItem;
};

export const CupraFooter = (props: FooterProps) => {
  const { className } = props;

  const showroom = useShowroom();

  const showroomSettings = useShowroomSettings(['footer_menu', 'facebook_url', 'instagram_url', 'youtube_url']);

  const socialMediaIcons = {
    instagram:
      'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/Footer/instagram_jLbmwVQ6P.svg?updatedAt=1705973416241',
    facebook: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/Footer/facebook_BAJFo9VzA.svg?updatedAt=1705973476125',
    youtube: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/Footer/youtube_0CQq6c5i7.svg?updatedAt=1705973497543',
  };
  const socialLinks = Object.keys(socialMediaIcons)
    .map((code) => ({
      href: showroomSettings[`${code}_url`],
      src: socialMediaIcons[code],
    }))
    .filter((item) => !!item.href);

  return (
    <footer className={classnames(styles.footer, className)}>
      <div className={styles.preFooter}>
        <Container isFluid className={styles.container}>
          <div className={styles.boxPreFooter}>
            <p className="hug">I want to know more about CUPRA</p>
            <div className={styles.ctaContainer}>
              <a
                href="https://www.cupraofficial.co.nz/contact-us/keep-me-in-the-know.html"
                className={styles.cta}
                target={'_self'}
              >
                <span>Keep me informed</span>
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.globalFooter}>
        <Container isFluid className={styles.container}>
          <div className={styles.containerFooter}>
            <div className={styles.footerButtons}>
              {socialLinks.map((socialLink, i) => (
                <a key={i} href={socialLink.href}>
                  <img src={socialLink.src} />
                </a>
              ))}
            </div>
            <div className={styles.footerLegal}>
              <ul>
                {(showroomSettings.footer_menu || []).map((menuItem, i) => (
                  <>
                    <MenuItem key={i} item={menuItem} />
                    {i < (showroomSettings.footer_menu || []).length - 1 && <li>|</li>}
                  </>
                ))}
              </ul>
            </div>
            <div className={styles.footerCopyright}>
              {showroom.name}. {new Date().getFullYear()} ®
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

function MenuItem(props: MenuItemProps) {
  const { item } = props;

  return (
    <li>
      {item.href ? (
        <Link href={item.href}>
          <a>{item.label}</a>
        </Link>
      ) : (
        <span>{item.label}</span>
      )}
    </li>
  );
}
