import Container from '../../../Container';
import { FooterProps } from '../../types';

export default function PlaceholderFooter(props: FooterProps) {
  return (
    <footer style={{ padding: '8rem 0', background: '#f2f2f2', marginTop: '1.5rem' }}>
      <Container>
        <p>This footer, like everything else on this page, is customisable.</p>
      </Container>
    </footer>
  );
}
