import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { d } from '../../../../services/formatCurrency';
import { n } from '../../../../services/formatNumber';
import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { MenuItem } from '../../../../types/menu';
import Button from '../../../Button';
import Container from '../../../Container';
import actewaglLogo from './actewaglLogo.png';
import styles from './index.module.scss';
import soc2Logo from './soc2Logo.png';
import holonIQLogo from './holonIQLogo.png';
import vyroLogo from './vyroLogo.svg';
import { PromoDates } from '../../../../services/promos/constants';
import dayjs from 'dayjs';
import { FooterProps } from '../../types';

export default function StandardFooter(props: FooterProps) {
  const { legal, className } = props;

  const showroom = useShowroom();
  const showroomSettings = useShowroomSettings([
    'footer_menu',
    'footer_cta',
    'facebook_url',
    'instagram_url',
    'linkedin_url',
    'twitter_url',
    'youtube_url',
    'indigenous_recognition',
    'finance_locked_comparison_rate',
    'horizontal_logo',
    'vehicle_order_min_deposit',
  ]);

  const socialMediaIcons = {
    facebook: <BsFacebook />,
    instagram: <BsInstagram />,
    linkedin: <BsLinkedin />,
    twitter: <BsTwitter />,
    youtube: <BsYoutube />,
  };
  const socialLinks = Object.keys(socialMediaIcons)
    .map((code) => ({
      href: showroomSettings[`${code}_url`],
      icon: socialMediaIcons[code],
    }))
    .filter((item) => !!item.href);

  const getTelLink = (phone: string) => {
    if (typeof phone === 'string') {
      return 'tel:' + phone.replace(/ /g, '');
    }
    return null;
  };

  return (
    <footer id="footer" className={classnames(styles.footer, className)}>
      <Container>
        <div className={styles.top}>
          <div className={styles.logo}>
            <img src={showroomSettings.horizontal_logo} alt={showroom.name} />
          </div>

          {showroomSettings.footer_cta && (
            <div className={styles.cta}>
              <div className={styles.description}>{showroomSettings.footer_cta.description}</div>
              <Button className="hug" color="primary" size="small" href={showroomSettings.footer_cta.href}>
                {showroomSettings.footer_cta.label}
                <AiOutlineArrowRight className="inlineIcon suffix" />
              </Button>
            </div>
          )}
        </div>

        <div className={styles.middle}>
          <ul className={styles.contact}>
            {showroom.phone && (
              <li className={styles.item}>
                <a href={getTelLink(showroom.phone)}>{showroom.phone}</a>
              </li>
            )}
            {showroom.email && (
              <li className={styles.item}>
                <a href={`mailto:${showroom.email}`}>{showroom.email}</a>
              </li>
            )}
          </ul>
          {showroom.code === 'vyro' && (
            <ul className={styles.contact}>
              <li className={styles.item}>
                Head office: <br />
                <a href="https://www.google.com/maps/place/Vyro/@-33.8649694,151.2059052,15z/data=!4m2!3m1!1s0x0:0x1fc0d0de221c26fc?sa=X&ved=2ahUKEwjE28GU5sr6AhX7yHMBHdMOB60Q_BJ6BAhgEB8">
                  Level 8, 11 York Street, Sydney NSW 2000
                </a>
              </li>
            </ul>
          )}

          {!!showroomSettings.footer_menu?.length && (
            <nav className={styles.menu}>
              <ul>
                {(showroomSettings.footer_menu as Array<MenuItem>).map((item, i) => (
                  <FooterMenuItem key={i} item={item} />
                ))}
              </ul>
            </nav>
          )}

          <div className={styles.social}>
            {socialLinks.map((socialLink, i) => (
              <a key={i} href={socialLink.href}>
                {socialLink.icon}
              </a>
            ))}
          </div>
          {showroomSettings.indigenous_recognition && <p>{showroomSettings.indigenous_recognition}</p>}
        </div>

        <div className={styles.bottom}>
          <div className={styles.logo}>
            {showroom.code === 'actewagl' && (
              <>
                <a href="https://www.actewagl.com.au/" target="_blank" className={styles.actewaglLogo}>
                  <Image src={actewaglLogo} width={208} height={49} alt="ActewAGL" />
                </a>
                <a href="https://www.vyro.com.au/" target="_blank" className={styles.vyroLogo}>
                  <Image src={vyroLogo} width={111} height={47} alt="Vyro" />
                </a>
              </>
            )}
            {showroom.code === 'vyro' && (
              <>
                <a
                  className={styles.affiliateLogo}
                  target="_blank"
                  href="https://www.our-trace.com/brand/vyro"
                  title="Vyro is proudly a Climate Positive organisation through Trace"
                >
                  <img src={"https://ik.imagekit.io/vyro/vyro-footer-links/traceLogo24_FAskl2m25.png?updatedAt=1713227908354"} height={88} />
                </a>
                <a
                  className={styles.affiliateLogo}
                  target="_blank"
                  href="https://ik.imagekit.io/vyro/vyro-footer-links/Vyro%20Pty%20Ltd.%20SOC2%20Type%20II%20Report%20-%20Final_F8GwgJPhw.pdf?updatedAt=1693442571916"
                  title="Vyro SOC2 Type I report"
                >
                  <Image src={soc2Logo} width={88} height={88} />
                </a>
                <a
                  className={styles.affiliateLogo}
                  target="_blank"
                  href="https://www.holoniq.com/notes/2022-australia-new-zealand-climate-tech-100"
                  title="HolonIQ Top 100 Climate Tech Australia & New Zealand"
                >
                  <Image src={holonIQLogo} width={88} height={88} />
                </a>
              </>
            )}
          </div>

          <div className={styles.legal}>
            © {new Date().getFullYear()} Vyro Pty Ltd
            {' | '}
            <a href="/privacy-policy">Privacy</a>
            {' | '}
            <a href="/downloads/terms.pdf">Terms of use</a>
            <br />
            <br />
            NSW Motor Dealership Licence -{' '}
            <a
              className={styles.mdlLink}
              href="https://verify.licence.nsw.gov.au/details/Motor/1-3RY6GI0"
              target="_blank"
            >
              MD089471
            </a>
            <br />
            ACT Traders Licence -{' '}
            <a
              className={styles.mdlLink}
              href="https://ik.imagekit.io/vyro/vyro-footer-links/ACT%20Trader%20Certificate_LIGnX2mqX.pdf?updatedAt=1713223956629"
              target="_blank"
            >
              20000609
            </a>
            <br />
            <br />
            <span style={{ whiteSpace: 'nowrap' }}>ACN: 653 976 099</span>
            <br />
            <span style={{ whiteSpace: 'nowrap' }}>ABN: 98 653 976 099</span>
          </div>
        </div>

        <div className={styles.disclaimers}>
          <p className={styles.finePrint}>
            This information does not constitute financial advice and you should consider whether it is appropriate to
            your circumstances before you act in reliance on it. Any opinions, forecasts or recommendations reflect the
            judgement of Vyro, and (where applicable) our finance partners as at the date of publication and may change
            later without notice.
          </p>

          {showroom.code === 'vyro' && (
            <>
              <p className={styles.finePrint}>
                ^ Finance provided by a panel of lenders orchestrated by Vyro's financing partner Driva.
              </p>

              <p className={styles.finePrint}>
                Pre-approval means your application for finance has been approved subject to the provision of additional
                information, satisfaction of the lender’s credit criteria and confirmation of your capacity to service
                the loan. Pre-approval is not an offer of credit.
              </p>

              <p className={styles.finePrint}>
                * The comparison rate applies only to the example or examples given. Different amounts and terms will
                result in different comparison rates. Costs such as redraw fees or early repayments, and cost savings
                such as fee waivers, are not included in the comparison rate but may have influence over the cost of the
                loan.
              </p>
            </>
          )}

          {showroom.code === 'actewagl' && (
            <>
              <p className={styles.finePrint}>
                ^ Plenti RE Limited ABN 57 166 646 635 holds an Australian credit license number 449176. Finance
                provided by Plenti Finance Pty Ltd ABN 82 636 759 861, a credit provider. Both Plenti RE Limited and
                Plenti Finance Pty Ltd are members of the Australian Financial Complaints Authority (AFCA).
              </p>

              <p className={styles.finePrint}>
                * Estimated monthly cost amount is based on a secured loan amount of the $30,000 loan with a 60 month
                term, {d(showroomSettings.vehicle_order_min_deposit)} down payment, and a{' '}
                {n(showroomSettings.finance_locked_comparison_rate, 2)}% p.a. comparison rate, and assumes a borrower
                with an excellent credit score. {n(showroomSettings.finance_locked_comparison_rate, 2)}% p.a. comparison
                rate is based on a secured Consumer Loan amount of the $30,000 loan for a 60 month term and assumes a
                borrower with an excellent credit score. Calculations are based on total DriveAway price as configured
                and are not reflective of any potential incentives, subsidies or savings. WARNING: This comparison rate
                applies only to the example given and may not include all fees and charges. Different amounts or terms,
                will result in different comparison rates. Comparison rate is correct as at 13/9/2022 and is subject to
                change. This estimate is not an offer, quote or approval of finance. The actual repayment amount and
                comparison rate will be confirmed if an application is submitted and approved. All applications are
                subject to credit assessment, approval and eligibility criteria. Terms, conditions, fees and charges
                apply.
              </p>

              <p className={styles.finePrint}>
                ActewAGL works with a number of electric vehicle supply partners to provide these products and services.
                While ActewAGL works closely with its partners to ensure that they provide the relevant products and
                services, ActewAGL does not accept any responsibility or liability for any representations or warranties
                provided by any of its electric vehicle partners.
              </p>

              <p className={styles.finePrint}>
                [2] $1,000 discount applied off the full retail price of a new Hyundai Kona Elite or Highlander when a
                deposit is made by 11:59pm {dayjs(PromoDates.actewagl.date).format('DD/MM/YYYY')}. The discount will be
                reflected on your Final Invoice prior to your vehicle delivery. Please note, $1,000 discount is limited
                to one per registered customer and cannot be applied across multiple orders or other vehicles purchased.
                Discount value is non-transferable and cannot be exchanged for cash.
              </p>
            </>
          )}

          <p className={styles.finePrint}>
            [1] Price on Application - Final price will be confirmed subject to supplier availability and any applicable
            additional charges.
          </p>

          {legal && <p className={styles.finePrint}>{legal}</p>}
        </div>
      </Container>
    </footer>
  );
}

type FooterMenuItemProps = {
  item: MenuItem;
};

function FooterMenuItem(props: FooterMenuItemProps) {
  const { item } = props;

  const hasChildren = !!item.items?.length;

  return (
    <li className={styles.item}>
      <Link href={item.href || '#'}>
        <a>{item.label}</a>
      </Link>

      {hasChildren && (
        <ul>
          {item.items.map((child, i) => (
            <FooterMenuItem key={i} item={child} />
          ))}
        </ul>
      )}
    </li>
  );
}
