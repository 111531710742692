import dayjs from 'dayjs';

export const PromoDates = {
  actewagl: {
    date: new Date('2023-05-31'),
  },
  vyro: {
    date: new Date('2022-12-31'),
  },
  polestarNz: {
    date: new Date('2023-06-30'),
  },
};

export const PromoPeriod = {
  afs: {
    startDate: new Date('2023-04-15'),
    endDate: new Date('2023-07-31'),
  },
};

export const isExpired = (promoDate: Date) => {
  return dayjs().isAfter(dayjs(promoDate), 'day');
};

export const isPromotionActive = (startDate: Date, endDate: Date) => {
  const now = dayjs();
  return now.isBefore(endDate) && now.isAfter(startDate);
};
